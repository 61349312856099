<template>
		<div class="cad-request-link">
		<v-card >
		<v-toolbar elevation="0" color="#414241" dark dense>
		<h3 style="font-family:Arial;color:white;font-weight:bold;" class="mt-1"> {{cadRequestDetails.originalFileName}} </h3>
		<v-spacer></v-spacer>
		
		<v-tooltip bottom transition="scroll-y-transition">
			<template v-slot:activator="{on}">
				<v-btn :disabled="isPDFLoadError" @click="printPDF()" v-on="on" class="mr-3" text small color="white">
					<v-icon> mdi-printer </v-icon>
					Print
				</v-btn>
			</template>
			<span> Print PDF </span>
		</v-tooltip>

		<v-tooltip bottom transition="scroll-y-transition">
			<template v-slot:activator="{on}">
				<v-btn :disabled="isPDFLoadError" v-on="on" @click="downloadPDF()" text  small color="white;" class="mr-5">
				<v-icon> mdi-download</v-icon>
				Download
				</v-btn>		
			</template>
			<span> Download File </span>
		</v-tooltip>


		</v-toolbar>
		<center> <h1 v-if="isPDFLoadError" class="mt-5" style="font-family:Arial"> No Attachment Found, Please contact Administrator.. </h1></center>
		<center> <h1 v-if="!isPDFLoadError && !isPDFLoad" class="mt-5" style="font-family:Arial"> Please Wait While Opening PDF File </h1></center>
		<center> <v-img v-if="!isPDFLoadError && !isPDFLoad"  max-width="500" max-height="500" :src ="PDFloadingGIF" > </v-img>  </center>
		<iframe v-if="isPDFLoad" :src="`${src}#toolbar=0`" frameborder="0" style="margin:0; padding:0px;border:0;top:0px;left:0px;bottom:0px;right:0px;" width="100%"  height="100%" allowfullscreen ></iframe>
		</v-card>
		</div>

		<!-- <PDFView
            v-if="isPDFLoad"
            :src.sync="src"
            ref="pdfView"
            :fileName="cadRequestDetails.filename"
            :sidebarFeatureVisible="true"
            :downloadFeatureVisible="true"
            :toolbarVisible="true"
            :scale.sync="scale"
		>

     
			<template slot="left-toolbox">
				<span class="ml-4 fStyle">{{cadRequestDetails.originalFileName}} </span>
              
				<v-btn fab icon small @click="zoomIn()">
					<v-icon color="white">mdi-magnify-plus-outline</v-icon>
				</v-btn>
				<v-btn fab icon small @click="zoomOut()">
					<v-icon color="white">mdi-magnify-minus-outline</v-icon>
				</v-btn>    

				<input ref="autoFocus" type="text" style="height:1px;width:1px" @keydown="handleCtrlKey" @keyup="handleUnpressCTRL"/>
                
                <v-spacer></v-spacer>

                <v-tooltip bottom transition="scroll-y-transition">
                    <template v-slot:activator="{on}">
                    
                        <v-btn  @click="downloadPDF()" icon class="mr-3" v-on="on" > 
                            <v-icon color="white">mdi-download</v-icon>
                        </v-btn>
                    </template>
                    <span style="font-family:Arial"> Download </span>
                </v-tooltip>

                <v-tooltip bottom transition="scroll-y-transition"> 
                    <template v-slot:activator="{on}">
                        <v-btn @click="CloseWindow()" icon v-on="on" > 
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span  style="font-family:Arial"> Close </span>
                </v-tooltip>
			</template>

           
          

			<template slot="error">
				<span>&nbsp;</span>
			</template>
			
			<template slot="loading"></template>
			
		</PDFView> -->

</template>

<script>
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
// import axios from "axios";
import * as binconv from "binconv";
// import { PDFView } from "vue_pdfjs_viewer";
import axios from 'axios'

export default {
	props: {

	},
	components: {
		// PDFView,
	},
	data() {
		return {
			sidePage: false,
			scale: "auto",
			src: "",
            cadRequestDetails: {
                filename: null,
                originalFileName: null,
				filename_attachment : null // 2023-03-20
            }, 
            isPDFLoad: false,
			browserZoomLevel: 0,
            objCADRequestMaterial: {},
			isPDFRendered: false,
			isPDFLoadError: false,
			PDFloadingGIF: require('../assets/PDFLoading3.gif'),
		};
	},//End of data
	mounted(){
		
	},
	created() {
        
		console.log( 'line250>>  ', this.$route.params.id );
		console.log('line251>> ', this.$route.query.filename );

        this.LoadCADRequestMaterials()
		// setTimeout(() => {
		// 	this.scale = '1'

		// 	this.loadPdf()
		// 	//
		// 	}, 1200);

		setInterval(() => {
          if(  this.$refs.autoFocus) {
            this.$refs.autoFocus.focus();
          }
        }, 1000);

		window.addEventListener("resize", this.zoom);

	},
	watch:{
	// 	browserZoomLevel(val) {
	// 	console.log('line116',val)
	// 	if( val ) {
	// 		setTimeout(() => {
	// 			// document.body.style.zoom = 100
	// 			document.firstElementChild.style.zoom = "reset";
	// 		}, 500);
	// 	}
	//   }
	},//End of watch
	methods: {
		zoom(){
			console.log('zooom')
			setTimeout(() => {
				// document.body.style.zoom = "reset";
				let wSize = window.visualViewport.scale;
				if( wSize == 1) {
					document.body.style.zoom = '100%'
				}
			}, 800);
		},
		getSizes ()  {
			const body = document.body;
			body.width = window.innerWidth;
			body.height = window.innerHeight;
			console.log(body.width, body.height);
		},
		handleUnpressCTRL() {
			window.removeEventListener('mousewheel', this.MouseWheelHandler);
		},
		handleCtrlKey( event ) {
		// console.log( event.keyCode )
		if( event.keyCode == 17 || event.which == '17' ) {
		console.log('You pressed CTRL')
		window.addEventListener('mousewheel', this.MouseWheelHandler );
		event.preventDefault();
		
		} else{
			console.log('Not press CTRL')
		}
		
		},
    
	MouseWheelHandler(  ) {
		var e = window.event || e;
		console.log('mousewheel_event', e)
		var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
		this.browserZoomLevel = Math.round( window.devicePixelRatio * 100 );
		if(delta > 0 ) {
		console.log('up')
		if (this.scale == "auto") {
			this.scale = "1";
		}
		else{
			let num = parseInt(this.scale);
			let b = String(num + 1);
			this.scale = b;
		}
		} else{
		console.log('down')
		if (this.scale != "1") {
			let num = parseInt(this.scale);
			let b = String(num - 1);
			this.scale = b;
		}
		
	}
		
	// e.preventDefault();
               
	},

       downloadFile(pdfUrl) {  // Download function PDF using BLOB
        fetch(pdfUrl).then(resp => resp.arrayBuffer()).then(resp => {
            // set the blog type to final pdfconst file = new Blob([resp], {type: 'application/pdf'});
            // process to auto download itconst fileURL = URL.createObjectURL(file);
            const file = new Blob([resp], {type: 'application/pdf'});

            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = this.cadRequestDetails.filename;
            link.click();
          });
       },
        downloadPDF(){
          this.downloadFile(this.src);
        },
        CloseWindow(){
            window.close();
        },
		pdfPrint(){
			this.$refs.pdfView.print()

		},
		zoomIn() {

			if (this.scale == "auto") {
				this.scale = "1";
			
			}
			else{
				let num = parseInt(this.scale);
				let b = String(num + 1);
				this.scale = b;
			}
			
		},
		zoomOut() {
			if (this.scale != "1") {
				let num = parseInt(this.scale);
				let b = String(num - 1);
				this.scale = b;
			}
		},


		promiseLoadCADRequestMaterialsData(){   // 2023-03-23
			return new Promise((resolve,reject) => {
                this.isPDFLoad = false
				axios.defaults.headers.common["x-api-key"] =
				"H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
				const url = `${this.api}cadRequestMaterials/GetSpecificCADRequest/${this.$route.params.id}`
				axios.get(url).then(res=>{
					if( res.data ) {
						let index =  res.data.subItems.findIndex( rec=>{
							return rec.filename_attachment == this.$route.query.filename  // 2023-03-23
						})
						let toResolve = {
							originalFileName: res.data.subItems[index].filename_attachment,
							filename : `${res.data.subItems[index].filename_attachment}.${res.data.subItems[index].extension}`,
							filename_attachment: `${res.data.subItems[index].filename_attachment}.${res.data.subItems[index].extension}` // 2023-03-23
						}
						resolve( toResolve );
					} 
				}).catch(err=>{
					reject({ message: err.message })
				})
			})
		},

		promiseGetPDFFile( cadRequestDetails ){
			return new Promise((resolve,reject) => {
			var tunnelingAgent = tunnel.httpsOverHttp({
			proxy: {
			// Proxy settings
			host: "hrdproxy.hrd-s.com",
			port: 81,
			proxyAuth: "administrator:system",
			},
			})

			AWS.config.update({
			httpOptions: { agent: tunnelingAgent },
			region: "us-east-2",
			credentials: config,
			});

			var s3 = new AWS.S3({
			AccessKeyID: this.accessKeyId,
			SecretAccessKey: this.secretAccessKey,
			Region: "us-east-2",
			params: {
			bucket: "rulebook.files",
			},
			});
			var options = {
			Bucket: "rulebook.files",
			Key: `CAD依頼資料/${cadRequestDetails.filename_attachment}`
			};
			s3.getObject(options, async (err, data) => {
			if (err) {
			console.log('line324', err.message );
				reject( err.message )
			}
			else {
			var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
			type: `application/pdf`,
			});

			// var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
			// type: `${data.ContentType};charset=utf-8`,
			// });
			let url = URL.createObjectURL(blob);
			resolve(url)
			}
			});
			})
		},

		async LoadCADRequestMaterials(){  // 2023-03-23 
			try{
			
				this.isPDFLoad = false;
				this.isPDFLoadError = false;

				let cadRequestDetails = await this.promiseLoadCADRequestMaterialsData();
				
				let srcPDF = await this.promiseGetPDFFile( cadRequestDetails );
				
				document.title = `${cadRequestDetails.originalFileName}`

				this.src = srcPDF;
				
				this.cadRequestDetails = cadRequestDetails

				this.isPDFLoad = true;

			}catch(err){
				console.log(err.message);
				alert( 'Error Attachment');
				this.isPDFLoadError = true;
				this.isPDFLoad = false;
			}
		},

		printPDF(){ // 2023-03-23
			var objFra = document.createElement('iframe'); // Create an IFrame.
			objFra.style.visibility = "hidden"; // Hide the frame.
			objFra.src = this.src; // Set source not done .pdf.
			objFra.onload = function(){
			objFra.contentWindow.focus(); // Set focus.
			objFra.contentWindow.print(); // Print it  
			};
			document.body.appendChild(objFra); // Add the frame to the web page.
		},

        LoadCADRequestMaterials2(){  // old function 2023-03-23
           this.isPDFLoad = false
           axios.defaults.headers.common["x-api-key"] =
              "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
            const url = `${this.api}cadRequestMaterials/GetSpecificCADRequest/${this.$route.params.id}`
            axios.get(url).then(res=>{

                this.cadRequestDetails = res.data

                let index =  res.data.subItems.findIndex( rec=>{
                    //  return rec.filename == this.$route.query.filename
					return rec.filename == this.$route.query.filename.split('_')[0]  // 2023-03-20
                })

                this.cadRequestDetails = {
                    originalFileName: res.data.subItems[index].filename,
                    filename : `${res.data.subItems[index].filename}.${res.data.subItems[index].extension}`,
					filename_attachment: `${res.data.subItems[index].filename_attachment}.${res.data.subItems[index].extension}` // 2023-03-20
                }

				let self = this;
				document.title = `${this.cadRequestDetails.originalFileName}`

				var tunnelingAgent = tunnel.httpsOverHttp({
					proxy: {
					// Proxy settings
					host: "hrdproxy.hrd-s.com",
					port: 81,
					proxyAuth: "administrator:system",
					},
				});

				AWS.config.update({
					httpOptions: { agent: tunnelingAgent },
					region: "us-east-2",
					credentials: config,
				});
				
				var s3 = new AWS.S3({
					AccessKeyID: this.accessKeyId,
					SecretAccessKey: this.secretAccessKey,
					Region: "us-east-2",
					params: {
					bucket: "rulebook.files",
					},
				});

				var options = {
					Bucket: "rulebook.files",
					// Key: `CAD依頼資料/${this.cadRequestDetails.filename}`,
					Key: `CAD依頼資料/${this.cadRequestDetails.filename_attachment}`,  // 2023-03-20
				};
				s3.getObject(options, async function(err, data) {
					if (err) {
						console.log(err, err.stack);
						self.isPDFLoadError = true 
						alert('PDF file is not found in file server, Please contact Administrator')
					}
					// an error occurred
					else {
					// console.log(data, "s3Data");

					var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
						type: `${data.ContentType};charset=utf-8`,
					});

					// let url = URL.createObjectURL(blob);
					// self.src = url   // tanskie
					
					self.src = await self.blobToBase64( blob )
					
					// console.log('line311', self.src );
			
					}
				});

			// get filename
			console.log('index', index)
			console.log('line234',this.cadRequestDetails)
			this.isPDFLoad = true})
        
		},
		blobToBase64(blob) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});
		},

		loadPdf(){
            console.log('loadPDF')

			let self = this;

            document.title = `${self.cadRequestDetails.originalFileName}`

			var tunnelingAgent = tunnel.httpsOverHttp({
				proxy: {
				// Proxy settings
				host: "hrdproxy.hrd-s.com",
				port: 81,
				proxyAuth: "administrator:system",
				},
			});

			AWS.config.update({
				httpOptions: { agent: tunnelingAgent },
				region: "us-east-2",
				credentials: config,
			});
			
			var s3 = new AWS.S3({
				AccessKeyID: this.accessKeyId,
				SecretAccessKey: this.secretAccessKey,
				Region: "us-east-2",
				params: {
				bucket: "rulebook.files",
				},
			});
			
			var options = {
				Bucket: "rulebook.files",
				// Key: `CAD依頼資料/${this.cadRequestDetails.filename}`,
				Key: `CAD依頼資料/${this.cadRequestDetails.filename_attachment}`, // 2023-03-20
			};
			s3.getObject(options, async function(err, data) {
				if (err) {
					console.log(err, err.stack);
					alert('PDF file is not found in file server, Please contact Administrator')
				}
				// an error occurred
				else {
				// console.log(data, "s3Data");

				var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
					type: `${data.ContentType};charset=utf-8`,
				});

				// let url = URL.createObjectURL(blob);
				// self.src = url   // tanskie
				
				self.src = await self.blobToBase64( blob )
				
				// console.log('line311', self.src );
			
				}
			});
		},
		
	},//End of methods
};
</script>

<style scoped >
	@media only print {
		#app {
		display: none !important;
		}
	}

	#text {
		position: fixed;
		top: 90%;
		left: 5%;
		font-size: 25px;
		color: #ff0000;
		transform: translate(-10%, -10%);
		-ms-transform: translate(-30%, -30%);
	}

	.fStyle{
		font-size: 14px; 
		font-family: Arial, Helvetica, sans-serif;
	}

	.cad-request-link{
		height: 100%;
		/* overflow: hidden; */
	}
	.v-card {
		border-width: thin;
		display: block;
		max-width: 100%;
		outline: none;
		text-decoration: none;
		transition-property: box-shadow, opacity;
		overflow-wrap: break-word;
		position: relative;
		white-space: normal;
		height: 100%;
    }


   
</style>